@import '../../../../scss/theme-bootstrap';

// article text template
.la-article-text {
  margin: 0 auto;
  padding: 0 15px;
  @include breakpoint($portrait-up) {
    padding: 0;
    // content widths
    &.la-article-text--width-50 {
      width: 50%;
    }
    &.la-article-text--width-60 {
      width: 60%;
    }
    &.la-article-text--width-70 {
      width: 70%;
    }
    &.la-article-text--width-80 {
      width: 80%;
    }
    &.la-article-text--width-90 {
      width: 90%;
    }
    &.la-article-text--width-100 {
      width: 100%;
    }
  }
  h1 {
    @include h1;
  }
  h2,
  .at-h2__la_article {
    @include h2;
  }
  h3,
  .at-h3__la_article {
    @include h3;
  }
  h4 {
    @include h4;
  }
}
